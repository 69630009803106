exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pickup-drink-tsx": () => import("./../../../src/pages/pickup/drink.tsx" /* webpackChunkName: "component---src-pages-pickup-drink-tsx" */),
  "component---src-pages-pickup-food-tsx": () => import("./../../../src/pages/pickup/food.tsx" /* webpackChunkName: "component---src-pages-pickup-food-tsx" */),
  "component---src-pages-pickup-index-tsx": () => import("./../../../src/pages/pickup/index.tsx" /* webpackChunkName: "component---src-pages-pickup-index-tsx" */),
  "component---src-pages-pickup-preview-tsx": () => import("./../../../src/pages/pickup/preview.tsx" /* webpackChunkName: "component---src-pages-pickup-preview-tsx" */),
  "component---src-pages-pickup-remark-tsx": () => import("./../../../src/pages/pickup/remark.tsx" /* webpackChunkName: "component---src-pages-pickup-remark-tsx" */),
  "component---src-pages-reservation-drink-tsx": () => import("./../../../src/pages/reservation/drink.tsx" /* webpackChunkName: "component---src-pages-reservation-drink-tsx" */),
  "component---src-pages-reservation-food-tsx": () => import("./../../../src/pages/reservation/food.tsx" /* webpackChunkName: "component---src-pages-reservation-food-tsx" */),
  "component---src-pages-reservation-index-tsx": () => import("./../../../src/pages/reservation/index.tsx" /* webpackChunkName: "component---src-pages-reservation-index-tsx" */),
  "component---src-pages-reservation-preview-tsx": () => import("./../../../src/pages/reservation/preview.tsx" /* webpackChunkName: "component---src-pages-reservation-preview-tsx" */),
  "component---src-pages-reservation-remark-tsx": () => import("./../../../src/pages/reservation/remark.tsx" /* webpackChunkName: "component---src-pages-reservation-remark-tsx" */)
}

